import React from 'react';

interface AnimatedBorderProps {
  children: React.ReactNode;
  className?: string;
}

export function AnimatedBorder({ children, className = '' }: AnimatedBorderProps) {
  return (
    <div className={`relative group ${className}`}>
      {/* Animated border container */}
      <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-500/40 via-white/20 to-blue-500/40 rounded-lg opacity-50 group-hover:opacity-75 transition duration-1000 group-hover:duration-200 animate-gradient-x blur-sm"></div>
      
      {/* Content container */}
      <div className="relative rounded-lg bg-black">
        {children}
      </div>
      
      {/* Moving light effect */}
      <div className="absolute inset-0 rounded-lg">
        <div className="absolute inset-0 rounded-lg overflow-hidden">
          <div className="absolute top-0 left-[-100%] h-[1px] w-[200%] bg-gradient-to-r from-transparent via-white/20 to-transparent animate-moveX"></div>
          <div className="absolute bottom-0 left-[-100%] h-[1px] w-[200%] bg-gradient-to-r from-transparent via-white/20 to-transparent animate-moveX"></div>
          <div className="absolute left-0 top-[-100%] w-[1px] h-[200%] bg-gradient-to-b from-transparent via-white/20 to-transparent animate-moveY"></div>
          <div className="absolute right-0 top-[-100%] w-[1px] h-[200%] bg-gradient-to-b from-transparent via-white/20 to-transparent animate-moveY"></div>
        </div>
      </div>
    </div>
  );
}