import React, { useEffect, useState } from 'react';

export function RevenueCounter() {
  const [count, setCount] = useState(0);
  const targetAmount = 7000000;
  const duration = 2000; // 2 seconds animation
  const steps = 60; // 60 steps for smooth animation
  const increment = targetAmount / steps;

  useEffect(() => {
    let currentStep = 0;
    const interval = setInterval(() => {
      if (currentStep < steps) {
        setCount(Math.min(Math.round(increment * (currentStep + 1)), targetAmount));
        currentStep++;
      } else {
        clearInterval(interval);
      }
    }, duration / steps);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative">
      <p className="text-sm md:text-base text-white text-center">
        Find out how we generated €{count.toLocaleString()}+
        <br />
        using modern, cash-generating strategies
      </p>
    </div>
  );
}
