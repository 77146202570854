import React from 'react';
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";

const socialLinks = [
  { icon: FaInstagram, href: "https://www.instagram.com/onepercent.ie/" },
  { icon: FaXTwitter, href: "https://x.com/onepercent_ie" },
  { icon: FaYoutube, href: "#" },
  { icon: FaFacebook, href: "#" },
  { icon: FaTiktok, href: "https://www.tiktok.com/@onepercent.ie" }
];

const links = [
  { text: "Contact Us", href: "https://discord.gg/NJgGSuaby5" },
  { text: "Privacy Policy", href: "/privacy-policy" },
  { text: "Terms & Conditions", href: "/terms-and-conditions" },
];

export function Footer() {
  return (
    <footer className="bg-black border-t border-white/10">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="flex flex-col items-center gap-8">
          {/* Logo can go here if needed */}
          
          {/* Social Media Icons */}
          <div className="flex gap-6">
            {socialLinks.map((Social, index) => (
              <a
                key={index}
                href={Social.href}
                className="text-white/60 hover:text-white transition-colors"
              >
                <Social.icon className="w-5 h-5" />
              </a>
            ))}
          </div>

          {/* Links */}
          <div className="flex gap-8">
            {links.map((link, index) => (
              <a
                key={index}
                href={link.href}
                className="text-white/60 hover:text-white transition-colors"
              >
                {link.text}
              </a>
            ))}
          </div>

          {/* Copyright */}
          <div className="text-white/40 text-sm">
            © {new Date().getFullYear()} One Percent. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}