import React from 'react';

interface LogoProps {
  size: 'small' | 'large';
}

export function Logo({ size }: LogoProps) {
  const sizeClasses = {
    small: 'w-8 h-8',
    large: 'w-[75px] h-[75px] sm:w-[75px] sm:h-[75px] md:w-[75px] md:h-[75px]'
  };

  return (
    <svg className={sizeClasses[size]} viewBox="0 0 697 886" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" d="M554.000000,1.000005 
	C600.833313,1.000000 647.666687,1.007372 694.500000,0.976701 
	C696.680847,0.975273 698.923157,0.561908 697.631958,4.501489 
	C693.510498,10.325444 689.515198,15.495402 686.045776,20.997004 
	C671.802612,43.583344 657.731506,66.278152 643.573792,88.918457 
	C632.738281,106.246033 621.825684,123.525566 611.022583,140.873260 
	C595.379089,165.993698 579.859131,191.191193 564.181885,216.290482 
	C550.539917,238.131271 536.674011,259.832245 523.050842,281.684631 
	C507.397614,306.793182 491.995209,332.058228 476.313049,357.148560 
	C460.362122,382.668884 444.181427,408.045532 428.158569,433.521088 
	C425.680847,437.460571 423.509369,441.592682 420.611420,446.657959 
	C483.724884,437.812042 541.079102,448.109772 590.431824,489.015991 
	C639.205811,529.442566 665.432617,581.409607 666.825378,644.862366 
	C668.047363,700.536560 650.081299,749.651245 611.755615,790.532715 
	C574.313904,830.471130 528.106079,852.745850 473.324005,855.731567 
	C434.364014,857.854980 396.765778,851.337402 362.415314,831.934937 
	C311.359802,803.096680 276.791199,760.752930 259.665161,703.267334 
	C258.424561,704.146912 257.791748,705.477112 257.020416,706.721436 
	C236.685196,739.528015 216.363937,772.343201 195.978897,805.118774 
	C181.702667,828.072327 167.293869,850.943481 153.048309,873.915955 
	C150.442368,878.118286 148.336655,882.630737 146.000015,887.000000 
	C97.666672,887.000000 49.333344,887.000000 1.000009,887.000000 
	C1.000000,886.000000 1.000000,885.000000 1.300395,883.801758 
	C3.070671,882.021973 4.839681,880.627686 5.964836,878.830322 
	C19.365187,857.423584 32.648098,835.943359 46.006088,814.510071 
	C56.980659,796.901184 68.014717,779.329468 79.022110,761.740967 
	C93.104706,739.238586 107.190224,716.737976 121.268372,694.232788 
	C132.187714,676.777283 143.102005,659.318542 154.010788,641.856384 
	C168.073578,619.345642 182.116898,596.822632 196.190887,574.318909 
	C207.110062,556.859436 218.099518,539.443787 228.992432,521.967957 
	C242.945419,499.582855 256.832306,477.156555 270.748993,454.748779 
	C273.257935,450.709015 275.773163,446.673126 278.982391,441.515472 
	C238.681702,446.768646 200.145340,445.599731 162.989853,430.976013 
	C125.827133,416.349457 95.954102,392.104095 72.071861,360.447723 
	C48.209812,328.818115 35.418831,292.808807 33.111664,253.391373 
	C29.782240,196.508804 44.993492,145.366806 83.303253,102.314728 
	C121.959099,58.873722 170.411041,34.637283 228.712967,32.170376 
	C276.213806,30.160498 320.484192,41.329575 359.135529,70.092186 
	C398.180878,99.148003 425.860901,136.425766 438.775665,184.958206 
	C440.433472,183.168320 441.878296,182.027512 442.794434,180.557266 
	C460.569153,152.033279 478.255066,123.453972 496.016235,94.921509 
	C510.300110,71.975159 524.703979,49.103432 538.962280,26.141306 
	C544.112915,17.846596 548.995239,9.385291 554.000000,1.000005 
M317.000000,231.443329 
	C316.468658,217.128983 311.261536,204.516830 303.001556,193.019379 
	C289.404114,174.092514 270.960388,162.742783 247.933136,160.177414 
	C225.870712,157.719543 205.449875,163.399460 188.549957,178.148483 
	C165.768845,198.030197 156.409225,223.113144 161.983643,253.247894 
	C166.710739,278.802094 181.523209,297.006866 204.026062,308.862885 
	C220.023361,317.291351 237.213226,318.941803 255.080795,315.109680 
	C279.674744,309.834869 297.268768,295.790955 309.006226,273.900879 
	C315.981567,260.892090 318.261932,246.968201 317.000000,231.443329 
M448.564392,572.000000 
	C444.643890,572.878113 440.650940,573.516479 436.815430,574.675903 
	C420.173370,579.706055 407.089447,589.894348 396.921173,603.686707 
	C383.069397,622.475403 379.011200,643.509399 383.715576,666.366638 
	C387.422119,684.375732 396.746094,698.709045 410.809143,710.397095 
	C432.031738,728.035767 456.096832,731.690979 482.139740,725.296143 
	C493.567719,722.490051 503.206787,716.227539 512.248108,708.554382 
	C520.637268,701.434692 527.188416,692.989624 531.624023,683.364868 
	C538.076904,669.362732 540.571106,654.485718 538.164612,638.783020 
	C536.144409,625.601013 531.481628,613.757080 523.618652,603.220764 
	C515.654114,592.548340 505.689972,584.295715 493.393799,578.617371 
	C479.270844,572.095337 464.822662,569.494629 448.564392,572.000000 
z" />
    </svg>
  );
}