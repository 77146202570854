import React from 'react';

const testimonials = [
  {
    imageUrl: "https://images.unsplash.com/photo-1542831371-29b0f74f9713?auto=format&fit=crop&w=800",
    alt: "Discord Message 1"
  },
  {
    imageUrl: "https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5?auto=format&fit=crop&w=800",
    alt: "Discord Message 2"
  },
  {
    imageUrl: "https://images.unsplash.com/photo-1555949963-aa79dcee981c?auto=format&fit=crop&w=800",
    alt: "Discord Message 3"
  },
  {
    imageUrl: "https://images.unsplash.com/photo-1579403124614-197f69d8187b?auto=format&fit=crop&w=800",
    alt: "Discord Message 4"
  }
];

export function DiscordTestimonials() {
  return (
    <section className="py-20">
      <div className="max-w-7xl mx-auto px-4 mb-12">
        <h2 className="text-4xl font-bold text-white text-center mb-4">
          What Our Members Are Saying
        </h2>
        <p className="text-white/60 text-center text-lg">
          Real results from from those who have taken action
        </p>
      </div>

      <div className="max-w-7xl mx-auto px-4 relative">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 w-32 bg-gradient-to-r from-black to-transparent z-10" />
          <div className="absolute inset-y-0 right-0 w-32 bg-gradient-to-l from-black to-transparent z-10" />
          
          {/* Row 1 - Left to Right */}
          <div className="mb-4 overflow-hidden">
            <div className="flex gap-4 animate-scroll">
              {[...testimonials, ...testimonials].map((testimonial, index) => (
                <div
                  key={`row1-${index}`}
                  className="flex-shrink-0 w-[400px] h-[200px] rounded-lg overflow-hidden"
                >
                  <img
                    src={testimonial.imageUrl}
                    alt={testimonial.alt}
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
            </div>
          </div>
          
          {/* Row 2 - Right to Left */}
          <div className="mb-4 overflow-hidden">
            <div className="flex gap-4" style={{ animation: 'scroll 15s linear infinite reverse' }}>
              {[...testimonials, ...testimonials].map((testimonial, index) => (
                <div
                  key={`row2-${index}`}
                  className="flex-shrink-0 w-[400px] h-[200px] rounded-lg overflow-hidden"
                >
                  <img
                    src={testimonial.imageUrl}
                    alt={testimonial.alt}
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}