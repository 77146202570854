// src/pages/terms-and-conditions.tsx
import React from 'react';

export default function TermsAndConditions() {
  return (
    <div className="bg-black min-h-screen text-white/80" style={{ paddingTop: '100px' }}>
      <div className="max-w-4xl mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold text-white mb-8">Terms of Service</h1>
        <p className="text-sm mb-8">Last updated: {new Date().toLocaleDateString()}</p>

        <div className="space-y-8">
          <section>
            <p className="leading-relaxed mb-6">
              Welcome to One Percent! These Terms of Service ("Terms") govern your use of our website, 
              the purchase and access to our online courses, and participation in our online community. 
              By accessing or using our services, you agree to be bound by these Terms.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">1. Account Creation and Responsibilities</h2>
            <h3 className="text-xl font-semibold text-white/90 mb-2">1.1 Account Creation:</h3>
            <p className="leading-relaxed mb-4">
              To access our courses and community, you must create an account. You are responsible 
              for maintaining the confidentiality of your account and password.
            </p>

            <h3 className="text-xl font-semibold text-white/90 mb-2">1.2 Age Restriction:</h3>
            <p className="leading-relaxed">
              You must be at least 18 years old to create an account and access our services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">2. Online Courses</h2>
            <p className="leading-relaxed mb-4">
              Our courses include instructional materials, videos, and resources. Upon enrollment, 
              you receive access for the specified duration outlined during purchase.
            </p>
            <h3 className="text-xl font-semibold text-white/90 mb-2">2.1 License Restrictions:</h3>
            <p className="leading-relaxed">You may not:</p>
            <ul className="list-disc pl-6 space-y-2 mt-4">
              <li>Share or resell access to the courses</li>
              <li>Copy or redistribute course content</li>
              <li>Use the content for commercial purposes</li>
              <li>Create derivative works from our content</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">3. Refund Policy</h2>
            <p className="leading-relaxed mb-4">
              We offer a 7-day refund policy from the date of purchase, subject to the following conditions:
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Refund must be requested within 7 days of purchase</li>
              <li>You must not have completed your Student Success Onboarding Call</li>
              <li>Refunds will be processed within 3-5 business days</li>
              <li>Refunds will be issued to the original payment method</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">4. Community Guidelines</h2>
            <p className="leading-relaxed mb-4">When participating in our community, you agree to:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Treat all members with respect</li>
              <li>Maintain confidentiality of other members</li>
              <li>Use the community for lawful purposes only</li>
              <li>Not share offensive or inappropriate content</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">5. Intellectual Property</h2>
            <p className="leading-relaxed">
              One Percent retains all rights to the course content, community materials, and associated 
              intellectual property. Your purchase grants you a limited license to access and use the 
              content for personal use only.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">6. Disclaimer and Liability</h2>
            <p className="leading-relaxed">
              Our services are provided "as-is" without any warranties. One Percent is not liable 
              for any damages arising from your use of our services or inability to access them.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">7. Contact Information</h2>
            <p className="leading-relaxed">
              For any questions about these Terms, please contact us through our Discord server 
              or email at support@onepercent.ie
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}