import React, { useState } from 'react';
import { Play, Pause } from 'lucide-react';

const testimonials = [
  {
    name: "",
    role: "",
    thumbnail: "./vid1.mp4"
  },
  {
    name: "",
    role: "",
    thumbnail: "./vid1.mp4"
  },
  {
    name: "",
    role: "",
    thumbnail: "./vid1.mp4"
  },
  {
    name: "",
    role: "",
    thumbnail: "./vid1.mp4"
  },
  {
    name: "",
    role: "",
    thumbnail: "./vid1.mp4"
  },
  {
    name: "",
    role: "",
    thumbnail: "./vid1.mp4"
  },
  {
    name: "",
    role: "",
    thumbnail: "./vid1.mp4"
  },
  {
    name: "",
    role: "",
    thumbnail: "./vid1.mp4"
  }
];

export function Testimonials() {
  const [playingVideoIndex, setPlayingVideoIndex] = useState<number | null>(null);
  const [playingVideo, setPlayingVideo] = useState<HTMLVideoElement | null>(null);

  const isVideo = (url: string) => {
    return url.match(/\.(mp4|webm|ogg)$/i) !== null;
  };

  const handleVideoClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    const video = event.currentTarget.querySelector('video');
    if (!video) return;

    if (video.paused) {
      playingVideo?.pause();
      video.muted = false;
      video.play();
      setPlayingVideo(video);
      setPlayingVideoIndex(index);
    } else {
      video.pause();
      video.muted = true;
      setPlayingVideo(null);
      setPlayingVideoIndex(null);
    }
  };

  return (
    <section className="py-20 px-4">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold text-white text-center mb-16">
          Success Stories
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="group relative aspect-[4/5] bg-gray-900 rounded-lg overflow-hidden cursor-pointer hover:scale-105 transition-transform"
              onClick={(e) => isVideo(testimonial.thumbnail) ? handleVideoClick(e, index) : undefined}
            >
              {isVideo(testimonial.thumbnail) ? (
                <>
                  <video
                    src={testimonial.thumbnail}
                    className="w-full h-full object-cover opacity-75 group-hover:opacity-50 transition-opacity"
                    muted
                    loop
                    playsInline
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    {playingVideoIndex === index ? (
                      <Pause 
                        className="w-12 h-12 text-white opacity-30 group-hover:opacity-100 transition-opacity transform group-hover:scale-110" 
                        fill="white"
                      />
                    ) : (
                      <Play 
                        className="w-12 h-12 text-white opacity-30 group-hover:opacity-100 transition-opacity transform group-hover:scale-110" 
                        fill="white"
                      />
                    )}
                  </div>
                </>
              ) : (
                <img
                  src={testimonial.thumbnail}
                  alt={testimonial.name}
                  className="w-full h-full object-cover opacity-75 group-hover:opacity-50 transition-opacity"
                />
              )}
              <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-black/90 via-black/60 to-transparent">
                <p className="text-white font-semibold text-lg mb-1">{testimonial.name}</p>
                <p className="text-white/60 text-sm">{testimonial.role}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}