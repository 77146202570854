import React, { useState } from 'react';

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
}

export function Tooltip({ content, children }: TooltipProps) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
      className="relative"
    >
      {children}
      
      {isVisible && (
        <div className="absolute inset-0 bg-black/90 backdrop-blur-sm rounded-lg transition-all duration-200 flex items-center justify-center">
          <div className="p-6 max-w-[90%] max-h-[90%] overflow-y-auto">
            {content}
          </div>
        </div>
      )}
    </div>
  );
}