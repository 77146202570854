import React from 'react';
import { Sparkles } from 'lucide-react';
import { Logo } from './Logo';
import { IoEnterOutline } from "react-icons/io5";

export function Navbar() {
  return (
    <nav className="fixed w-full z-50 bg-black/80 backdrop-blur-sm border-b border-white/10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex-shrink-0 text-white">
            <a href="/">
              <Logo size="small" />
            </a>
          </div>
          <div className="relative group inline-block">
            {/* Glow effect */}
            <div className="absolute -inset-1 bg-gradient-to-br from-black to-gray-900 rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
            
            <a
              href="https://discord.gg/NJgGSuaby5"
              className="relative inline-flex items-center gap-2 px-6 py-2 bg-gradient-to-br from-black to-gray-900 text-white rounded-lg overflow-hidden transition-all duration-300 border border-white/10 hover:border-white/20 group-hover:scale-[1.02]"
            >
              <span className="relative z-10 group-hover:text-blue-400 transition-colors duration-300">
                Join Now
              </span>
              <IoEnterOutline className="w-4 h-4 relative z-10 transition-transform duration-500 group-hover:text-blue-400" />
              
              {/* Shine effect */}
              <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/5 to-transparent -translate-x-full group-hover:translate-x-full duration-1000 transition-transform" />
              
              {/* Gradient border effect */}
              <div className="absolute inset-px rounded-lg bg-gradient-to-br from-transparent to-blue-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />

              {/* Underline animation */}
              <div className="absolute bottom-0 left-0 w-full h-0.5 bg-black/40 transform -translate-x-full group-hover:translate-x-0 transition-transform duration-500 ease-in-out" />
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}