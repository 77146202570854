import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivacyPolicy from './components/pages/privacy-policy';
import TermsAndConditions from './components/pages/terms-and-conditions';
import { Navbar } from './components/Navbar';
import { Hero } from './components/Hero';
import { Comparison } from './components/Comparison';
import { Testimonials } from './components/Testimonials';
import { Courses } from './components/Courses';
import { Features } from './components/Features';
import { DiscordTestimonials } from './components/DiscordTestimonials';
import { Pricing } from './components/Pricing';
import { WhyElite } from './components/WhyElite';
import { FAQ } from './components/FAQ';
import { Footer } from './components/Footer';

function App() {
  return (
    <BrowserRouter>
      <div className="min-h-screen bg-black text-white">
        <Navbar />
        <Routes>
          <Route path="/" element={
            <main>
              <Hero />
              <Comparison />
              <Testimonials />
              <Courses />
              <Features />
              <DiscordTestimonials />
              <Pricing />
              <WhyElite />
              <FAQ />
            </main>
          } />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;