// src/pages/privacy-policy.tsx
import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div className="bg-black min-h-screen text-white/80" style={{ paddingTop: '100px' }}>
      <div className="max-w-4xl mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold text-white mb-8">Privacy Policy</h1>
        <p className="text-sm mb-8">Last updated: {new Date().toLocaleDateString()}</p>
        
        <div className="space-y-8">
          <section>
            <p className="leading-relaxed mb-6">
              Thank you for visiting the website of One Percent ("One Percent," "we," "us," or "our"). 
              We value your privacy and are committed to protecting your personal information. 
              This Privacy Policy outlines how we collect, use, disclose, and safeguard the information 
              you provide when using our website. By accessing or using our website, you consent to 
              the terms and conditions of this Privacy Policy.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">1. Information We Collect</h2>
            <h3 className="text-xl font-semibold text-white/90 mb-2">1.1 Personal Information:</h3>
            <p className="leading-relaxed mb-4">
              When you visit our website, we may collect certain personally identifiable information 
              that you voluntarily provide to us, including but not limited to your name, email address, 
              Discord username, and any other information that you choose to provide.
            </p>
            
            <h3 className="text-xl font-semibold text-white/90 mb-2">1.2 Non-Personal Information:</h3>
            <p className="leading-relaxed">
              We may also collect non-personal information about you automatically when you access 
              or use our website. This information may include your IP address, browser type, 
              device information, operating system, and other technical information.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">2. Use of Information</h2>
            <p className="leading-relaxed mb-4">We use your information to:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Provide and maintain our services</li>
              <li>Process your purchases and transactions</li>
              <li>Communicate with you about our services</li>
              <li>Send you important updates and announcements</li>
              <li>Improve our website and services</li>
              <li>Protect our legal rights and prevent misuse</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">3. Information Sharing</h2>
            <p className="leading-relaxed">
              We do not sell, trade, or rent your personal information to third parties. 
              We may share your information with trusted service providers who assist us in 
              operating our website, conducting our business, or servicing you, so long as 
              they agree to keep this information confidential.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">4. Data Security</h2>
            <p className="leading-relaxed">
              We implement appropriate security measures to protect your personal information. 
              However, please note that no method of transmission over the internet is 100% 
              secure, and we cannot guarantee absolute security.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">5. Your Rights</h2>
            <p className="leading-relaxed">
              You have the right to access, correct, or delete your personal information. 
              You may also opt out of receiving marketing communications from us at any time.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">6. Changes to Privacy Policy</h2>
            <p className="leading-relaxed">
              We may update this Privacy Policy from time to time. Any changes will be posted 
              on this page with an updated revision date.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">7. Contact Us</h2>
            <p className="leading-relaxed">
              If you have any questions about this Privacy Policy, please contact us through 
              our Discord server or email at support@onepercent.ie
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}